


















import {Component, Prop, Vue} from "vue-property-decorator";
import KeqingBotConfigerOperator, {ConfigOperateMode} from "@/utils/keqing-bot-configer-operator";

@Component
export default class ViewCommonConfigOperate extends Vue {
    private isLoading:boolean = true;
    @Prop({required:true}) mod!:string;
    @Prop({required:true}) configPaths!:string[];
    private cfgData:{[key:string]:any} | null = null;
    private err:{code:number, msg:string} | null = null;
    private _configOperator!:KeqingBotConfigerOperator;

    private mounted() {
        this.isLoading = true;
        this._configOperator = new KeqingBotConfigerOperator(this.mod);
        for(let path of this.configPaths) {
            this._configOperator.addAction(ConfigOperateMode.Get, path);
        }
        this._configOperator.submit().then(result => {
            this.cfgData = result;
            this.$emit('onReady', result);
        }).catch(e => {
            this.err = e;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public getData() {
        return this.cfgData;
    }

    public get configOperator() {
        return this._configOperator;
    }
}

import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotPermissionsFlagRemove extends ApiKeqingBotCmd<boolean> implements IApi {
    public path = '/keqing-helper/cmd/system/flag-permission-remove';
    public constructor(data:{
        target:number,
        flag:string,
    }) {
        super(data);
    }
}
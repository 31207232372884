import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export enum CommandBwlistType {
    BlackQQ = "black-qq",
    BlackGroup = "black-group",
    WhiteQQ = "white-qq",
    WhiteGroup = "white-group"
}

export class ApiKeqingBotCommandGetBwlist extends ApiKeqingBotCmd<number[]> implements IApi {
    public path = "/keqing-helper/cmd/system/command-bwlist";
    public constructor(data:{
        type: CommandBwlistType,
        cmd: string,
    }) {
        super(data);
    }
}
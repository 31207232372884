






















import {Component, Vue} from "vue-property-decorator";
import {ApiKeqingBotListBots, BotStatesInfo} from "@/request-apis/keqing-helper/bots";

@Component
export default class CompBotList extends Vue {
    private isListLoading:boolean = false;
    private list:BotStatesInfo[] = [];
    private mounted() {
        this.isListLoading = true;
        this.list = [];
        new ApiKeqingBotListBots().run().then(result => {
            this.list = result.list.sort((a,b) => {
                if(a.states != b.states) {
                    return a.states == 'online' ? -1 : 1;
                } else {
                    return 0;
                }
            });
        }).catch(err => {
            this.list = [];
            this.$message.error(`${err.msg}(${err.code})`);
        }).finally(() => {
            this.isListLoading = false;
        })
    }

    private onManageClick(botId:number, botName:string) {
        this.$emit('onSelect', botId, botName);
    }
}

import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotPermissionsRemoveGlobalBwlist extends ApiKeqingBotCmd<boolean> implements IApi {
    public path = "/keqing-helper/cmd/system/global-bwlist-remove";
    public constructor(data:{
        type: string,
        target: number
    }) {
        super(data);
    }
}



















































































































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ViewCommonConfigOperate from "@/components/admin/view-common-config-operate.vue";
import KeqingBotConfigerOperator, {ConfigOperateMode} from "@/utils/keqing-bot-configer-operator";
import {Utils} from "@/utils/utils";

type ConfigFormat = {
    wait_start_timeout: number,
    users_list: {[user:string]:UserListProtoItem},
    wol_http_api_url: string,
}

type UserListProtoItem = {
    ip:string,
    mac:string,
    port:number
}

type UserListItem = {
    key: string,
    qq: number,
    tempData?: UserListProtoItem & {qq?:string},
} & UserListProtoItem;

@Component({
    components: {ViewCommonConfigOperate}
})
export default class ViewWolConfig extends Vue {
    @Prop({required:true}) private type!:string;
    @Ref() private operateComp!:ViewCommonConfigOperate;
    private modId:string = 'moe.yumi.wol';
    private cfg!:ConfigFormat;
    private configPaths = [
        'wait_start_timeout',
        'users_list',
        'wol_http_api_url'
    ];
    private auxData:{[key:string]:any} = {
        router_host: {
            validate: {
                type:'',
                help:'',
                check: this.checkingHost,
            },
            isChanged: false,
            loading: false,
        },
        router_port: {
            isChanged: false
        },
        router_use_https: {
            loading: false
        },
        login_username: {
            isChanged: false,
            loading: false,
        },
        login_password: {
            isChanged: false,
            loading: false,
        },
        wait_start_timeout: {
            isChanged: false,
        },
        wol_http_api_url: {
            validate: {
                type:'',
                help:'',
                check: this.checkingWolHttpApiUrl,
            },
            isChanged: false,
            loading: false,
        }
    }
    private labelCol = {
        xs: { span: 24 },
        sm: { span: 5 },
    };
    private wrapperCol = {
        xs: { span: 24 },
        sm: { span: 12 },
    };
    private userListColumns = [
        {
            key: 'qq',
            title: 'QQ号码',
            width: '20%',
            scopedSlots: {customRender: 'qq'}
        },
        {
            key: 'mac',
            title: 'MAC',
            width: '20%',
            scopedSlots: {customRender: 'mac'}
        },
        {
            key: 'ip',
            title: 'IP地址',
            width: '20%',
            scopedSlots: {customRender: 'ip'}
        },
        {
            key: 'port',
            title: '外网端口',
            width: '20%',
            scopedSlots: {customRender: 'port'}
        },
        {
            key: 'edit',
            title: '操作',
            width: '20%',
            scopedSlots: {customRender: 'edit'}
        }
    ];
    private userListDataSource:UserListItem[] = [];


    private ipRegExp!:RegExp;

    private onReady(result:ConfigFormat) {
        this.ipRegExp = new RegExp('(?=(\\b|\\D))(((\\d{1,2})|(1\\d{1,2})|(2[0-4]\\d)|(25[0-5]))\\.){3}((\\d{1,2})|(1\\d{1,2})|(2[0-4]\\d)|(25[0-5]))(?=(\\b|\\D))')
        this.cfg = result;
        let userList:UserListItem[] = [];
        for(let key in this.cfg.users_list) {
            let qq = parseInt(key);
            let item = this.cfg.users_list[key];
            userList.push({
                key:key,
                qq: qq,
                ip: item.ip,
                mac: item.mac,
                port: item.port,
                tempData: undefined
            });
        }
        this.userListDataSource = userList;
    }

    private onChanged(path:string) {
        if(path in this.auxData) {
            let obj = this.auxData[path];
            if('validate' in obj) {
                try {
                    obj.validate.type = '';
                    obj.validate.help = '';
                    obj.validate.check();
                } catch (e) {
                    obj.validate.type = 'error';
                    obj.validate.help = e;
                    return;
                }
            }
            if('isChanged' in obj) {
                obj.isChanged = true;
            }
        }
    }

    private async submit(path:string, value?:any) {
        let obj = this.auxData[path];
        if(obj && 'isChanged' in obj) {
            if(!obj['isChanged']) {
                return;
            }
            obj['isChanged'] = false;
        }
        if(value === undefined) {
            value = Utils.dataGet(this.cfg, path, undefined);
        }
        if(obj && 'loading' in obj) {
            obj.loading = true;
        }
        await new KeqingBotConfigerOperator(this.modId)
            .addAction(ConfigOperateMode.Set, path, value)
            .submit();
        if(obj && 'loading' in obj) {
            obj.loading = false;
        }
    }

    private checkingHost() {
        // if(!this.ipRegExp.exec(this.cfg.router_host)) {
        //     throw '请输入正确的IP地址';
        // }
        return true;
    }

    private checkingWolHttpApiUrl() {
        if(this.cfg.wol_http_api_url.indexOf('{mac}') < 0 || this.cfg.wol_http_api_url.indexOf('{host}') < 0) {
            throw '请在URL中携带宏文本{mac}和{host}，mac为唤醒机器的网卡mac地址，host为唤醒机器的IP地址';
        }
        return true;
    }

    private onUserItemAddClick() {
        this.userListDataSource.splice(0, 0, {
            key: 'new',
            qq: NaN,
            ip: '',
            mac: '',
            port: 3389,
            tempData: {
                qq: '',
                ip: '',
                mac: '',
                port: 3389,
            },
        });
    }

    private onUserItemEdit(item:UserListItem) {
        item.tempData = {
            ip:item.ip,
            mac:item.mac,
            port:item.port,
        };
    }

    private onUserItemDelete(item:UserListItem) {
        this.$confirm({
            title:'删除项目',
            content: '确定删除该项吗？',
            onOk: async ()=> {
                await new KeqingBotConfigerOperator(this.modId)
                    .addAction(ConfigOperateMode.RemoveMapItem, 'users_list', item.key)
                    .submit();
                let index = this.userListDataSource.indexOf(item);
                if(index >= 0) {
                    this.userListDataSource.splice(index, 1);
                }
            }
        })
    }

    private async onUserItemSure(item:UserListItem) {
        if(item.tempData === undefined) return;
        if(item.tempData.qq !== undefined) {
            if(item.tempData.qq in this.cfg.users_list) {
                this.$message.error('用户已存在，请添加其他用户。');
                return;
            }
            item.key = item.tempData.qq;
            item.qq = parseInt(item.tempData.qq);
        }
        await new KeqingBotConfigerOperator(this.modId)
            .addAction<UserListProtoItem>(ConfigOperateMode.SetMapItem, 'users_list', item.key, {
                ip: item.tempData.ip,
                mac: item.tempData.mac,
                port: item.tempData.port,
            }).submit();
        item.mac = item.tempData.mac;
        item.ip = item.tempData.ip;
        item.port = item.tempData.port;
        item.tempData = undefined;
    }

    private onUserItemCancel(item:UserListItem) {
        if(!item.tempData) return;
        if(item.tempData.qq === undefined) {
            item.tempData = undefined;
        } else {
            this.userListDataSource.splice(0, 1);
        }
    }
}

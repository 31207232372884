import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotSendGroupMessage extends ApiKeqingBotCmd<void> implements IApi {
    public path = "/keqing-helper/cmd/system/qq-send-group-message"
    public constructor(datas:{
        target: number,
        msg: string | Array<any>
    }) {
        super(datas);
    }
}
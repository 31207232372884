import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";
import IApi from "@/managers/net/i-api";


export type OrderInfoResult = {
    qq: number,
    group: number,
    group_nickname: string | null,
    time: number,
}

export default class ApiKeqingBotRandomOrderGetInfo extends ApiKeqingBotCmd<OrderInfoResult> implements IApi {
    public path = "/keqing-helper/cmd/moe.yumi.random/order-info";
    public constructor(order:string) {
        super({order});
    }
}



import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotGetGroupList extends ApiKeqingBotCmd<IApiKeqingBotGetGroupListResult> implements IApi {
    public path = "/keqing-helper/cmd/system/group-list"
    public constructor() {
        super();
    }
}

export type IApiKeqingBotGetGroupListResult = {
    permission:{
        sendMsg:boolean,
        delete:boolean
    },
    list:IApiKeqingBotGroupItemData[]
}

export type IApiKeqingBotGroupItemData = {
    id:number,
    name:string,
    permission:string,
}
import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotPermissionsFlagList extends ApiKeqingBotCmd<string[]> implements IApi {
    public path = '/keqing-helper/cmd/system/flag-permissions';
    public constructor(data:{
        target:number
    }) {
        super(data);
    }
}
import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotCommandReleaseProtect extends ApiKeqingBotCmd<boolean> implements IApi {
    public path = "/keqing-helper/cmd/system/command-release-protect"
    public constructor(data:{
        cmd: string
    }) {
        super(data);
    }
}
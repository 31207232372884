



















































import {Component, Vue, Watch} from "vue-property-decorator";
import {ApiKeqingBotCommandAddBwlist} from "@/request-apis/keqing-helper/cmd/system/command-bwlist-add";
import {ApiKeqingBotCommandRemoveBwlist} from "@/request-apis/keqing-helper/cmd/system/command-bwlist-remove";
import {ApiKeqingBotCommandGetBwlist, CommandBwlistType} from "@/request-apis/keqing-helper/cmd/system/command-bwlist";
import {IApiKeqingBotGetCommandsListCommandItem} from "@/request-apis/keqing-helper/cmd/system/commands-list";

@Component
export default class CompCommandBwlistEdit extends Vue {
    private title:string = "";
    private cmdItem:IApiKeqingBotGetCommandsListCommandItem | null = null;
    private isOpen:boolean = false;
    private isLoading:boolean = false;
    private currentTabKey:CommandBwlistType = CommandBwlistType.BlackQQ;
    private tabs:{key:string, title:string, iconType:'qq'|'group'}[] = [
        {
            key: CommandBwlistType.BlackQQ,
            title: "QQ黑名单",
            iconType: 'qq',
        },
        {
            key: CommandBwlistType.BlackGroup,
            title: "群黑名单",
            iconType: 'group',
        },
        {
            key: CommandBwlistType.WhiteQQ,
            title: "QQ白名单",
            iconType: 'qq',
        },
        {
            key: CommandBwlistType.WhiteGroup,
            title: "群白名单",
            iconType: 'group',
        }
    ];
    private tags:Array<number> = [];
    private inputVisible:boolean = false;
    private inputValue:string =  '';
    private tagListError:{code:number,msg:string} | null = null;

    private mounted() {

    }

    public open(item:IApiKeqingBotGetCommandsListCommandItem, type: CommandBwlistType) {
        this.cmdItem = item;
        this.currentTabKey = type;
        this.title = `指令 [#${item.cmd}] 的黑白名单编辑`;
        this.refreshTags();
        this.isOpen = true;
    }

    private onTabChanged() {
        this.refreshTags();
    }

    @Watch('isOpen')
    private onModalVisibleChanged() {
        if(this.isOpen) {

        } else {
            this.title = "";
            this.cmdItem = null;
            this.inputVisible = false;
            this.inputValue = '';
        }
    }

    private onCancelClick() {
        this.isOpen = false;
    }

    private onTagCloseClick(target:number) {
        if(!this.cmdItem) return;
        new ApiKeqingBotCommandRemoveBwlist({
            type: this.currentTabKey,
            cmd: this.cmdItem.cmd,
            target: target,
        }).run().then(result => {
            this.$message.success(`删除[${target}]成功`);
        }).catch(e => {
            this.$message.error(`${e.msg}(${e.code})`);
        }).finally(() => {
            this.refreshTags();
            this.isLoading = false;
        })
        this.isLoading = true;
    }

    private handleInputChange(e:any) {
        this.inputValue = e.target.value;
    }

    private handleInputConfirm() {
        if(this.inputValue == '') {
            this.inputVisible = false;
            return;
        }
        if(!this.cmdItem) return;
        let inputValue = parseInt(this.inputValue);
        if(isNaN(inputValue)) {
            this.inputVisible = false;
            this.inputValue = '';
            this.$message.error("输入内容不合法");
            return;
        }
        if (inputValue && this.tags.indexOf(inputValue) === -1) {
            this.isLoading = true;
            new ApiKeqingBotCommandAddBwlist({
                type: this.currentTabKey,
                cmd: this.cmdItem.cmd,
                target: inputValue
            }).run().then(result => {
                this.tags.push(inputValue)
                this.$message.success('添加成功');
            }).catch(e => {
                this.$message.error(`${e.msg}(${e.code})`);
            }).finally(() => {
                this.inputVisible = false;
                this.inputValue = '';
                this.isLoading = false;
            })
        }
    }

    private refreshTags() {
        let key = this.currentTabKey;
        if(!this.cmdItem) {
            return;
        }
        this.tags = [];
        this.isLoading = true;
        new ApiKeqingBotCommandGetBwlist({
            type: key,cmd: this.cmdItem.cmd
        }).run().then(result => {
            this.tags = result;
            this.tagListError = null;
            this.isLoading = false;
        }).catch(e => {
            this.tagListError = e;
            this.isLoading = false;
        })

    }
}



















import {Vue, Component, Prop} from "vue-property-decorator";
import {
    IApiKeqingBotModuleItem,
    IApiKeqingBotModulePermissions
} from "@/request-apis/keqing-helper/cmd/system/modules-list";

@Component
export default class CompModuleItem extends Vue {
    @Prop({required: true}) item!:IApiKeqingBotModuleItem;
    @Prop({required: true}) permissions!:IApiKeqingBotModulePermissions;

    private onMenuItemClick(flag:string) {
        this.$emit('menuItemClick', flag, this.item);
    }
}

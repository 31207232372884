import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotGetCommandsList extends ApiKeqingBotCmd<IApiKeqingBotGetCommandsListResult> implements IApi {
    public path = "/keqing-helper/cmd/system/commands-list"
    public constructor() {
        super();
    }
}

export type IApiKeqingBotGetCommandsListResult = {
    mods: Array<IApiKeqingBotGetCommandsListModItem>,
    cmds: Array<IApiKeqingBotGetCommandsListCommandItem>,
    permissions: {
        protected: boolean,
        qqBlackList: boolean,
        qqWhiteList: boolean,
        groupBlackList: boolean,
        groupWhiteList: boolean,
    }
}

export type IApiKeqingBotGetCommandsListModItem = {
    id: string,
    name: string,
}

export type IApiKeqingBotGetCommandsListCommandItem = {
    cmd: string,
    alias: any[],
    desc: string,
    modId: string,
    protected: boolean,
    protectMsg?: string,
}
import Api, {ApiMethod} from "@/managers/net/Api";
import IApi from "@/managers/net/i-api";

type ResponseType = {
    code: number,
    msg: string,
    list: Array<BotStatesInfo>
}

export type BotStatesInfo = {
    id: number,
    nickname: string,
    states: 'online' | 'offline'
}

export class ApiKeqingBotListBots extends Api<ResponseType> implements IApi {
    public path = "/keqing-helper/bots";
    public method = ApiMethod.GET;
    public constructor() {
        super();
    }
}





























































































import {Component, Vue} from "vue-property-decorator";
import ApiKeqingBotRandomOrderGetList from "@/request-apis/keqing-helper/cmd/moe.yumi.random/order-list";
import ApiKeqingBotRandomOrderDelete from "@/request-apis/keqing-helper/cmd/moe.yumi.random/order-delete";
import ApiKeqingBotRandomOrderGetInfo, {OrderInfoResult} from "@/request-apis/keqing-helper/cmd/moe.yumi.random/order-info";
import ApiKeqingBotRandomOrderAdd from "@/request-apis/keqing-helper/cmd/moe.yumi.random/order-add";

@Component
export default class ViewRandomOrderManage extends Vue {
    private states:string = 'loading';
    private data:Array<string> = [];
    private permission:{add:boolean,remove:boolean} = {add:false, remove:false};
    private searchData:Array<string> | null = null;
    private error?:{code:number, msg:string};

    private currentPage:number = 1;
    private pageSize:number = 100;
    private totalSize:number = 0;

    private orderInfoVisible:boolean = false;
    private orderInfo:(OrderInfoResult | {time: Date}) | null = null;
    private showAddOrderInput:boolean = false;
    private addOrderName:string = "";

    private deleteOrder:string | null = null;

    private mounted() {
        this.refresh();
    }

    private onPageChanged(page:number, pageSize:number) {
        this.pageSize = pageSize;
        this.refresh();
    }

    private onTagClick(tag:string) {
        this.orderInfoVisible = true;
        this.orderInfo = null;
        new ApiKeqingBotRandomOrderGetInfo(tag).run().then(result => {
            this.orderInfo = {
                qq: result.qq,
                group: result.group,
                group_nickname: result.group_nickname,
                time: new Date(result.time * 1000)
            };
        }).catch(e => {
            this.orderInfo = null;
            this.orderInfoVisible = false;
            this.$error({
                title: '出错了',
                content: `${e.msg}(${e.code})`,
            });
        });
    }

    private onSearchClick(value:string) {
        if(value) {
            new ApiKeqingBotRandomOrderGetInfo(value).run().then(result => {
                this.searchData = [value];
            }).catch(e => {
                this.searchData = [];
            });
        } else {
            this.searchData = null;
            this.refresh();
        }
    }

    private onAddOrderShowClick(visible:boolean) {
        this.showAddOrderInput = visible;
    }

    private onAddOrderClick(value:string) {
        new ApiKeqingBotRandomOrderAdd(value).run().then(result => {
            this.$message.success(`添加[${value}]成功`);
        }).catch(e => {
            this.$message.error(`${e.msg}(${e.code})`);
        }).finally(() => {
            this.addOrderName = '';
        })
    }

    private onRemoveClick(tag:string, isAddToDeletedList:boolean) {
        new ApiKeqingBotRandomOrderDelete(tag, isAddToDeletedList).run().then(result => {
            this.$message.success(`删除[${tag}]成功`);
            if(this.searchData !== null) {
                let index = this.searchData.indexOf(tag);
                if(index >= 0) {
                    this.searchData.splice(index, 1);
                }
            } else {
                this.refresh();
            }
        }).catch(e => {
            this.$message.error(`${e.msg}(${e.code})`);
        }).finally(() => {
            this.deleteOrder = null;
        })
    }

    private onOrderInfoClose() {
        this.orderInfoVisible = false;
    }

    private onOrderDeleteClose() {
        this.deleteOrder = null;
    }

    private async refresh() {
        let page = this.currentPage;
        let size = this.pageSize;
        this.states = 'loading';
        try {
            let data = await new ApiKeqingBotRandomOrderGetList(page, size).run();
            this.totalSize = data.total;
            this.data = data.data;
            this.permission = data.permission;
            this.states = 'success';
        } catch(e) {
            this.error = e;
            this.data = [];
            this.permission = {add:false, remove:false};
            this.states = 'error';
        }
    }
}

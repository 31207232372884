




import {Vue} from "vue-property-decorator";

export default class ViewSetuConfig extends Vue {

}

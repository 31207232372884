






























import {Component, Vue} from "vue-property-decorator";
import {ApiKeqingBotPermissionsFlagAdd} from "@/request-apis/keqing-helper/cmd/system/flag-permission-add";
import {ApiKeqingBotPermissionsFlagRemove} from "@/request-apis/keqing-helper/cmd/system/flag-permission-remove";
import {ApiKeqingBotPermissionsFlagList} from "@/request-apis/keqing-helper/cmd/system/flag-permissions";

@Component
export default class ViewPermissionsManage extends Vue {
    private target:number = -1;
    private permissionsDataSource:Array<string> | null = null;
    private addLoading:boolean = false;

    private mounted() {

    }

    private onSearch(v:string) {
        let target = parseInt(v);
        if(isNaN(target)) {
            this.$message.error('输入内容不合法');
            return;
        }
        this.doGetList(target);
    }

    private onAddClick(v:string) {
        if(this.target <= 0) return;
        this.addLoading = true;
        new ApiKeqingBotPermissionsFlagAdd({
            target: this.target,
            flag: v
        }).run().then(result => {
            if(result) {
                this.permissionsDataSource?.push(v);
                this.$message.success('添加成功');
            } else {
                this.$message.error('添加失败');
            }
        }).catch(e => {
            this.$message.error(`${e.msg}(${e.code})`);
        }).finally(() => {
            this.addLoading = false;
        })
    }

    private onDelClick(permission:string) {
        if(this.target <= 0) return;
        this.$confirm({
            title: '删除权限',
            content: `确定删除[${permission}]权限吗？`,
            onOk:() => {
                return new Promise((resolve, reject) => {
                    new ApiKeqingBotPermissionsFlagRemove({
                        target: this.target,
                        flag: permission,
                    }).run().then(result => {
                        if(result) {
                            this.doGetList(this.target);
                            resolve(true);
                        } else {
                            reject();
                        }
                    }).catch(e => {
                        reject();
                    }).finally(() => {

                    })
                }).catch(() => {
                    this.$message.error(`操作失败`);
                });
            },
            onCancel:() => {

            },
        })
    }

    private doGetList(target:number) {
        new ApiKeqingBotPermissionsFlagList({
            target
        }).run().then(result => {
            this.permissionsDataSource = result;
            this.target = target;
        }).catch(e => {
            this.permissionsDataSource = null;
            this.$message.error(`${e.msg}(${e.code})`);
            this.target = -1;
        })
    }
}

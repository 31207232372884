import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotSetCommandProtect extends ApiKeqingBotCmd<boolean> implements IApi {
    public path = "/keqing-helper/cmd/system/command-protect"
    public constructor(data:{
        cmd:string,
        msg:string,
    }) {
        super(data);
    }
}
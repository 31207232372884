import IApi from "@/managers/net/i-api";
import {ApiKeqingBotCmd} from "@/request-apis/keqing-helper/cmd";

export class ApiKeqingBotGetFriendList extends ApiKeqingBotCmd<IApiKeqingBotGetFriendListResult> implements IApi {
    public path = "/keqing-helper/cmd/system/friend-list"
    public constructor() {
        super();
    }
}

export type IApiKeqingBotGetFriendListResult = {
    permission:{
        sendMsg:boolean,
        delete:boolean
    },
    list:IApiKeqingBotFriendItemData[]
}

export type IApiKeqingBotFriendItemData = {
    id:number,
    nickname:string,
    remark:string,
}
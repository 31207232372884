




























import {Vue, Component, Prop} from "vue-property-decorator";
import {ApiKeqingBotCommandReleaseProtect} from "@/request-apis/keqing-helper/cmd/system/command-release-protect";
import {IApiKeqingBotGetCommandsListCommandItem} from "@/request-apis/keqing-helper/cmd/system/commands-list";

@Component
export default class CompCommandItem extends Vue {
    @Prop({required: true}) item!:IApiKeqingBotGetCommandsListCommandItem;
    @Prop({default: false}) permissionProtected!:boolean;
    @Prop({default: false}) permissionQqBlackList!:boolean;
    @Prop({default: false}) permissionQqWhiteList!:boolean;
    @Prop({default: false}) permissionGroupBlackList!:boolean;
    @Prop({default: false}) permissionGroupWhiteList!:boolean;


    private onProtectedClick(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.$emit('menuItemClick', 'protect', item);
    }

    private onReleaseProtectedClick(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.$confirm({
            title: '解除维护',
            content: `确定解除指令 [#${item.cmd}] 的维护状态吗？`,
            onOk: async () => {
                let result = await new ApiKeqingBotCommandReleaseProtect({
                    cmd: item.cmd,
                }).run();
                if(result) {
                    item.protected = false;
                    item.protectMsg = "";
                    this.$message.success(`解除维护成功`);
                } else {
                    this.$message.error(`操作失败`);
                    throw null;
                }
            },
            onCancel:() => {

            },
        });
    }

    private onQQBlackClick(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.$emit('menuItemClick', 'blackQQ', item);
    }

    private onQQWhiteClick(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.$emit('menuItemClick', 'whiteQQ', item);
    }

    private onGroupBlackClick(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.$emit('menuItemClick', 'blackGroup', item);
    }

    private onGroupWhiteClick(item:IApiKeqingBotGetCommandsListCommandItem) {
        this.$emit('menuItemClick', 'whiteGroup', item);
    }
}

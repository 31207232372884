import {ApiKeqingBotListBots, BotStatesInfo} from "@/request-apis/keqing-helper/bots";
import {ApiKeqingBotStates} from "@/request-apis/keqing-helper/bot-states";
// import {
//     ApiKeqingBotGetFriendList,
//     IApiKeqingBotFriendItemData
// } from "@/request-apis/keqing-helper/cmd/system/friend-list";
// import {EventDispatcher} from "@/utils/event-dispatcher";
// import {ApiKeqingBotGetGroupList, IApiKeqingBotGroupItemData} from "@/request-apis/keqing-helper/cmd/system/group-list";

export default class BotManager {
    private static _inst:BotManager | null = null;
    public static getInstance() {
        if(this._inst == null) {
            this._inst = new BotManager();
        }
        return this._inst;
    }

    private _botsList:BotStatesInfo[] | null = null;

    // private _friendsList:IApiKeqingBotFriendItemData[] | null = null;
    // private _groupsList:IApiKeqingBotGroupItemData[] | null = null;
    //
    // private _friendsListError:any = null;
    // private _groupsListError:any = null;

    public get currentSelectBot():number | undefined {
        let operation = localStorage.getItem('operation_bot');
        if(operation == null) {
            return undefined;
        } else {
            let result = parseInt(operation);
            if(isNaN(result)) {
                return undefined;
            } else {
                return result;
            }
        }
    }

    public async getBotsList(reload:boolean = false):Promise<BotStatesInfo[]> {
        if(reload) {
            let res = await new ApiKeqingBotListBots().run();
            if(res.code == 0) {
                this._botsList = res.list;
            } else {
                throw res;
            }
            return this._botsList;
        } else {
            if(this._botsList == null) {
                return await this.getBotsList(true);
            }
            return this._botsList;
        }
    }

    public async getBotStatesInfo(bot:number) {
        return await new ApiKeqingBotStates(bot).run();
    }

    public setBot(bot:number) {
        if(bot >= 0) {
            localStorage.setItem('operation_bot', bot + '');
        } else {
            localStorage.removeItem('operation_bot');
        }
    }

    /**
     * 获取QQ好友列表
     */
    // public async getFriendsList():Promise<IApiKeqingBotFriendItemData[]> {
    //     if(this._friendsListError) {
    //         throw this._friendsListError;
    //     }
    //     if(this._friendsList) {
    //         return this._friendsList;
    //     } else {
    //         try {
    //             let data = await new ApiKeqingBotGetFriendList().run();
    //             this._friendsList = data.list;
    //             return this._friendsList;
    //         } catch (e:any) {
    //             this._friendsListError = e;
    //             throw e;
    //         }
    //     }
    // }

    /**
     * 获取QQ群列表
     */
    // public async getGroupsList():Promise<IApiKeqingBotGroupItemData[]> {
    //     if(this._groupsListError) {
    //         throw this._groupsListError;
    //     }
    //     if(this._groupsList) {
    //         return this._groupsList;
    //     } else {
    //         try {
    //             let data = await new ApiKeqingBotGetGroupList().run();
    //             this._groupsList = data.list;
    //             return this._groupsList;
    //         } catch(e:any) {
    //             this._groupsListError = e;
    //             throw e;
    //         }
    //     }
    // }
}
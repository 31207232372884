import Api, {ApiMethod} from "@/managers/net/Api";
import IApi from "@/managers/net/i-api";
import BotManager from "@/managers/bot-manager";

type ApiKeqingBotCmdResponse<T> = {
    code:number,
    msg:string,
    result:T
}

export class ApiKeqingBotCmd<ResultProtoType> extends Api<ApiKeqingBotCmdResponse<ResultProtoType>> implements IApi {
    public path = "/keqing-helper/cmd";
    public method = ApiMethod.POST;
    public constructor(datas?:any) {
        super(function() {
            let cur = BotManager.getInstance().currentSelectBot;
            if(cur === undefined) {
                return undefined;
            } else {
                return {
                    bottarget: cur
                }
            }
        }, datas , {
            'Content-Type': 'application/json',
        });
    }

    //@ts-ignore
    public async run():Promise<ResultProtoType> {
        let response = await super.run();
        return response.result;
    }
}
















































































import {Component, Ref, Vue} from "vue-property-decorator";
import CompCommandProtected from "@/components/admin/comp-command-protected.vue";
import CompCommandBwlistEdit from "@/components/admin/comp-command-bwlist-edit.vue";
import CompCommandItem from "@/components/admin/comp-command-item.vue";
import {
    ApiKeqingBotGetCommandsList, IApiKeqingBotGetCommandsListCommandItem,
    IApiKeqingBotGetCommandsListResult
} from "@/request-apis/keqing-helper/cmd/system/commands-list";
import {CommandBwlistType} from "@/request-apis/keqing-helper/cmd/system/command-bwlist";

enum States {
    Loading = "loading",
    Success = "success",
    Error = "error"
}

@Component({
    components: {CompCommandItem, CompCommandBwlistEdit, CompCommandProtected}
})
export default class ViewCommandManage extends Vue {
    private states:States = States.Loading;
    private error:{code:number,msg:string} | null = null;
    private result:IApiKeqingBotGetCommandsListResult | null = null;
    private list:Array<{
        id:string,
        title:string,
        cmds:Array<IApiKeqingBotGetCommandsListCommandItem>
    }> = [];
    private collapseActiveKey:string = "";
    @Ref() private commandProtectedComp!:CompCommandProtected;
    @Ref() private commandBwlistComp!:CompCommandBwlistEdit;
    private searchDataSource:IApiKeqingBotGetCommandsListCommandItem[] = [];
    private searchResult:IApiKeqingBotGetCommandsListCommandItem[] | null = null;

    private mounted() {
        this.result = null;
        this.states = States.Loading;
        new ApiKeqingBotGetCommandsList().run().then(result => {
            this.result = result;
            this.refreshList();
            this.states = States.Success;
        }).catch(e => {
            this.error = e;
            this.states = States.Error;
        })
    }

    private onCmdItemMenuItemClick(type:string, item:IApiKeqingBotGetCommandsListCommandItem) {
        switch(type) {
            case 'protect': {
                this.commandProtectedComp.open(item);
                break;
            }

            case 'blackQQ': {
                this.commandBwlistComp.open(item, CommandBwlistType.BlackQQ);
                break;
            }

            case 'whiteQQ': {
                this.commandBwlistComp.open(item, CommandBwlistType.WhiteQQ);
                break;
            }

            case 'blackGroup': {
                this.commandBwlistComp.open(item, CommandBwlistType.BlackGroup);
                break;
            }

            case 'whiteGroup': {
                this.commandBwlistComp.open(item, CommandBwlistType.WhiteGroup);
                break;
            }
        }
    }

    private onSearchBoxSelect(value:string) {
        this.doSearch(value);
    }

    private onSearchBoxSearch(value:string) {

    }

    private onSearchBoxChange(value:string) {
        if(value) {
            let result:IApiKeqingBotGetCommandsListCommandItem[] = [];
            for(let mod of this.list) {
                for(let cmd of mod.cmds) {
                    if(cmd.cmd.indexOf(value) >= 0) {
                        result.push(cmd);
                    }
                }
            }
            this.searchDataSource = result;
        } else {
            this.searchResult = null;
            this.searchDataSource = [];
        }
    }

    private doSearch(value:string) {
        let result:IApiKeqingBotGetCommandsListCommandItem[] = [];
        for(let mod of this.list) {
            for(let cmd of mod.cmds) {
                if(cmd.cmd.indexOf(value) >= 0) {
                    result.push(cmd);
                }
            }
        }
        this.searchResult = result;
    }

    private refreshList() {
        let list:Array<{
            id:string,
            title:string,
            cmds:Array<IApiKeqingBotGetCommandsListCommandItem>
        }> = [];
        let temp:{[key:string]:IApiKeqingBotGetCommandsListCommandItem[]} = {};
        if(this.result) {
            for(let mod of this.result.mods) {
                let arr:IApiKeqingBotGetCommandsListCommandItem[] = [];
                temp[mod.id] = arr;
                list.push({
                    id: mod.id,
                    title: mod.name,
                    cmds: arr
                });
            }
            for(let cmd of this.result.cmds) {
                if(cmd.modId in temp) {
                    temp[cmd.modId].push(cmd);
                }
            }
        }
        this.list = list;
    }
}
